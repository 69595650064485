<template>
  <div
    v-if="showAppBanner"
    class="download-app-banner"
    :class="{ 'hide-mobile': hideMobile }"
  >
    <div class="dummy hide-small" />
    <div class="app-buttons" @click="handleClick">
      <div class="banner-header mb-1">
        <div class="logo-text">
          <BaseLogo class="hide-medium-up mr-1" />
          <BaseTitle class="banner-title text-light hide-medium-up">
            {{ $t(`download-app.title-${host}-small`) }}
          </BaseTitle>
        </div>
        <BaseTitle class="banner-title text-light centered hide-small">
          {{ $t(`download-app.title-${host}`) }}
        </BaseTitle>
        <BaseIcon
          icon="close"
          class="close-icon light hide-medium-up ml-1"
          @click.stop="showAppBanner = false"
        />
      </div>
      <AppStoreButtons />
    </div>
    <div class="phone-image hide-small ml-3">
      <BaseImage :src="`app-mockup-${host}.png`" class="" />
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  hideMobile?: boolean; // Hide the banner on mobile
}>();

const host = useCurrentHost();
const { mobileOs } = useUserAgent();

const showAppBanner = useState("SHOW_APP_BANNER", () => true);

const appStoreLink = computed(() => {
  // Allefolders links
  if (host.value === "allefolders") {
    if (mobileOs.value === "iOs") {
      return "https://apps.apple.com/nl/app/allefolders/id1086601848/";
    } else if (mobileOs.value === "android") {
      return "https://play.google.com/store/apps/details?id=com.jafolders.allefolders";
    }
  }

  // Promopromo links
  if (mobileOs.value === "iOs") {
    return "https://apps.apple.com/nl/app/promopromo-folders-van-belgi%C3%AB/id811303467";
  } else if (mobileOs.value === "android") {
    return "https://play.google.com/store/apps/details?id=com.jafolders.promopromo";
  }
});

const handleClick = () => {
  if (appStoreLink.value) {
    window.open(appStoreLink.value, "_blank");
  }
};
</script>

<style lang="scss" scoped>
// Styles shared between all devices
.download-app-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .banner-title {
    margin: 0;
  }

  .logo-text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  .app-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  > * {
    flex: 1;
  }
}

// Mobile style
@include for-small-screen {
  .download-app-banner {
    background-color: var(--primary);
    min-height: 20rem;
    padding: 2rem;
    position: fixed;
    bottom: 0;
    left: 0;

    &.hide-mobile {
      display: none;
    }

    .banner-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .phone-image,
    .dummy {
      display: none;
    }
  }
}

// Desktop style
$app-banner-padding-y: 4rem;

@include for-medium-screen-up {
  .download-app-banner {
    background-image: linear-gradient(
      transparent $app-banner-padding-y,
      var(--primary) $app-banner-padding-y,
      var(--primary) calc(100% - $app-banner-padding-y),
      $light-95 calc(100% - $app-banner-padding-y)
    );

    .phone-image {
      display: flex;
      max-height: 480px;
    }
  }
}
</style>
